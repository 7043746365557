import { useRequest } from "ahooks"
import { useParams } from "react-router-dom"
import { TransactionsService } from "../../../client"
import { rowClass, TITLE_SUFFIX } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { TransactionBody } from "./TransactionBody"
import { TransactionHeader } from "./TransactionHeader"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { useState } from "react"
import { TokenTransactionBody } from "./TokenTransactionBody"

const TransactionWrapper = () => {
  let { hash = "" } = useParams()
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const { data: transaction, loading } = useRequest(
    async () => TransactionsService.transactionsControllerGetTransaction(hash),
    { retryCount: 5 }
  )

  return (
    <>
      <HelmetWrapper
        title={`Transaction ${hash} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa Transaction ${hash}`}
      />
      <div className={rowClass}>
        <div className={"col-xl-4"}>
          <TransactionHeader tx={transaction} loading={loading} />
        </div>
        <div className={"col-xl-8"}>
          <TransactionBody transaction={transaction} loading={loading} />
          {transaction?.tokenTransactionData && (
            <TokenTransactionBody
              tokenTransaction={transaction.tokenTransactionData}
              loading={loading}
            />
          )}
          <AdsPlacement placementType="banner" platform={adsPlatform} />
        </div>
      </div>
    </>
  )
}

export { TransactionWrapper }
