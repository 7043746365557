export const useNavagator = () => {
  const toAddressDetail = (address: string) => {
    window.location.href = "/address/" + address
  }
  const toTransactionDetail = (hash: string) => {
    window.location.href = "/transaction/" + hash
  }
  const toBlockDetail = (hash: string) => {
    window.location.href = "/block/" + hash
  }
  const toKrc20TokenDetail = (ticker: string) => {
    window.location.href = "/token/krc20/" + ticker
  }

  return {
    toAddressDetail,
    toTransactionDetail,
    toBlockDetail,
    toKrc20TokenDetail,
  }
}

export const getTransactionRoute = (transactionId: string) => {
  return `/transaction/${transactionId}`
}

export const getBlockRoute = (blockId: string) => {
  return `/block/${blockId}`
}

export const getAddressRoute = (address: string) => {
  return `/address/${address}`
}

export const getTokenRoute = (ticker: string) => {
  return `/token/krc20/${ticker}`
}
