/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetKrc20StatusResponse } from '../models/GetKrc20StatusResponse';
import type { GetKrc20TokenListResponse } from '../models/GetKrc20TokenListResponse';
import type { GetTokenSummaryResponse } from '../models/GetTokenSummaryResponse';
import type { GetTokenTransactionsResponse } from '../models/GetTokenTransactionsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokenService {

    /**
     * Return Kaspa KRC20 token network status
     * @returns GetKrc20StatusResponse
     * @throws ApiError
     */
    public static tokenControllerGetKrc20Status(): CancelablePromise<GetKrc20StatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/stats',
        });
    }

    /**
     * Return Kaspa KRC20 token list
     * @returns GetKrc20TokenListResponse
     * @throws ApiError
     */
    public static tokenControllerGetKrc20TokenList(): CancelablePromise<GetKrc20TokenListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/tokens',
        });
    }

    /**
     * Return summary of a Kaspa KRC20 token
     * @param ticker A Kaspa KRC20 ticker
     * @returns GetTokenSummaryResponse
     * @throws ApiError
     */
    public static tokenControllerGetTokenSummary(
        ticker: string,
    ): CancelablePromise<GetTokenSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/{ticker}/info',
            path: {
                'ticker': ticker,
            },
        });
    }

    /**
     * Return list of transactions from Kaspa KRC20 tokens
     * @param nextCursor Start from this cursor
     * @param address Filter by address
     * @param ticker A Kaspa KRC20 token ticker
     * @returns GetTokenTransactionsResponse
     * @throws ApiError
     */
    public static tokenControllerGetTokenTransactions(
        nextCursor?: string,
        address?: string,
        ticker?: string,
    ): CancelablePromise<GetTokenTransactionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/krc20/transactions',
            query: {
                'nextCursor': nextCursor,
                'address': address,
                'ticker': ticker,
            },
        });
    }

}
