import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../client"
import { Tooltip } from "antd"
import { formatCash } from "../../utils/utils"

export const TokenMintedProgressiveBar = ({
  token,
}: {
  token: GetTokenSummaryResponse
}) => {
  const intl = useIntl()

  const { totalMinted, decimal, maxSupply, mintLimit } = token
  const currentSupplyPct = (totalMinted / maxSupply) * 100
  const pctToColor = (pct: number) => {
    if (pct === 100) {
      return "success"
    } else if (pct > 80) {
      return "warning"
    } else {
      return "dark"
    }
  }
  return (
    <div className="d-flex align-items-center">
      <Tooltip
        title={intl.formatMessage(
          { id: "CURRENT_SUPPLY_X" },
          {
            currentSupply: formatCash(totalMinted / Math.pow(10, decimal)),
          }
        )}
      >
        <div className="d-flex flex-column w-100 me-2">
          <div className="d-flex flex-stack mb-2">
            <span className="text-body me-2 fs-7 fw-semibold">
              {currentSupplyPct.toFixed()}%
            </span>
            {currentSupplyPct < 100 && (
              <span className="badge badge-light">
                {intl.formatMessage(
                  { id: "X_PER_MINT" },
                  {
                    mintLimit: formatCash(mintLimit / Math.pow(10, decimal)),
                  }
                )}
              </span>
            )}
          </div>
          <div className="progress h-5px w-100">
            <div
              className={`progress-bar bg-${pctToColor(currentSupplyPct)}`}
              role="progressbar"
              style={{ width: `${currentSupplyPct}%` }}
            ></div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
