/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import { Transaction } from "../../../client"
import { TransactionIdLabel } from "../../components/TransactionIdLabel"
import { TransactionStatusTag } from "../../components/TransactionStatusTag"
import { TransactionSide } from "../transaction/TransactionSide"
import { isLegacyTransaction } from "../transaction/utils"

interface BlockTransactionProps {
  tx?: Transaction
  loading?: boolean
}

const TransactionRow = ({ tx, loading }: BlockTransactionProps) => {
  if (!tx || loading) {
    return (
      <div className="row card-body border-top p-0">
        <div className="col-xl-6">
          <TransactionSide type="out" className="card-xl-stretch" loading />
        </div>
        <div className="col-xl-6">
          <TransactionSide type="in" className="card-xl-stretch" loading />
        </div>
      </div>
    )
  }

  return (
    <div className="row card-body border-top p-0">
      <div className="col-xl-6">
        <TransactionSide
          type="out"
          className="card-xl-stretch"
          txs={tx.inputs}
        />
      </div>
      <div className="col-xl-6">
        <TransactionSide
          type="in"
          className="card-xl-stretch"
          txs={tx.outputs}
        />
      </div>

      <div className="d-flex justify-content-between flex-wrap align-items-start">
        <TransactionIdLabel transactionId={tx.transactionId} />
        <div className="mx-5 fs-6 fw-bold text-end">
          <TransactionStatusTag
            accepted={tx.isAccepted}
            confirmations={tx.confirmations}
            unknown={isLegacyTransaction(tx)}
          />
        </div>
      </div>
    </div>
  )
}

interface BlockTransactionsProps {
  transactions?: Transaction[]
  loading?: boolean
}

const BlockTransactions: React.FC<BlockTransactionsProps> = ({
  transactions,
  loading,
}) => {
  const intl = useIntl()

  return (
    <div className={"card mb-5 mb-xl-10"}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({ id: "BLOCK.TRANSACTIONS.TITLE" })}{" "}
            {transactions ? `(${transactions.length})` : null}
          </h3>
        </div>
      </div>
      {loading
        ? [...Array(1)].map((tx: Transaction) => {
            return <TransactionRow tx={tx} />
          })
        : transactions?.map((tx: Transaction) => {
            return <TransactionRow tx={tx} />
          })}
    </div>
  )
}

export { BlockTransactions }
