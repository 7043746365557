/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { AddressesService } from "../../../client"
import { TITLE_SUFFIX } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { formatKaspaAmount, getRandomAdsPlatfrom } from "../../utils/utils"
import { AddressHeader } from "./components/AddressHeader"
import { AddressTransactions } from "./components/AddressTransactions"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { AddressBalanceHistory } from "./components/AddressBalanceHistory"
import { generateNonce } from "../../utils/secrets"
import { Krc20UIEnabled } from "../../utils/feature"

const mobileBottomMarginClass = "mb-5 mb-xl-0"
const row = `row g-xl-8 ${mobileBottomMarginClass} `
const PAGE_SIZE = 20

const AddressWrapper = () => {
  let { hash = "" } = useParams()
  const transactionsRef = useRef<null | HTMLDivElement>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const { data: addressInfo, loading: addressInfoLoading } = useRequest(
    async () =>
      await AddressesService.addressesControllerGetAddressSummary(hash)
  )
  const {
    data: addressTnx,
    loading: addressTnxLoading,
    error,
  } = useRequest(
    async () => {
      const nonce = await generateNonce()
      const resp =
        await AddressesService.addressesControllerGetAddressTransactions(
          nonce,
          hash,
          (currentPage - 1) * PAGE_SIZE,
          PAGE_SIZE
        )
      return resp
    },
    { refreshDeps: [currentPage] }
  )
  const transactionCount = addressInfo?.transactionCount || 0
  const totalPage = Math.ceil(transactionCount / PAGE_SIZE)

  const { data: addressTokens, loading: addressTokensLoading } = useRequest(
    async () => {
      if (!Krc20UIEnabled) return

      const resp = await AddressesService.addressesControllerGetAddressTokens(
        hash
        // "kaspatest:qypf2y3aw3ta2w6mwvtskp2jgn98uxct5vxj5rhrzrsh3gwnkp7jf4g0njgjshn"
      )
      return resp
    }
  )

  const onNextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  return (
    <div className={row}>
      <HelmetWrapper
        title={`Address ${hash} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa address ${hash} with balance of ${formatKaspaAmount(
          addressInfo?.balance
        )}`}
      />
      <div className={"col-xl-4"}>
        <AddressHeader
          address={hash}
          balance={addressInfo?.balance || 0}
          transactionCount={transactionCount}
          tags={addressInfo?.tags}
          infoLoading={addressInfoLoading}
          tokens={addressTokens}
          tokensLoading={addressTokensLoading}
        />
        {addressInfo?.balanceRecords.length ? (
          <AddressBalanceHistory
            loading={addressInfoLoading}
            balanceRecords={addressInfo?.balanceRecords}
          />
        ) : null}
      </div>

      <div ref={transactionsRef} className={"col-xl-8"}>
        <AddressTransactions
          address={hash}
          transactions={
            addressTnxLoading ? undefined : addressTnx?.transactions
          }
          loading={addressTnxLoading}
          error={error}
          totalPage={totalPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          transactionsCardRef={transactionsRef}
        />

        <div className="mt-5">
          <AdsPlacement placementType="banner" platform={adsPlatform} />
        </div>
      </div>
    </div>
  )
}

export { AddressWrapper }
