/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, KeyboardEvent } from "react"
import { KTSVG } from "../../_metronic/helpers"
import { NetworkDataService } from "../../client"
import { useNavagator } from "../hooks/navigator"
import { useIntl } from "react-intl"
import { message } from "antd"

const Toolbar = () => {
  const intl = useIntl()
  const [query, setQuery] = useState<string>("")
  const [loading, setLoading] = useState<Boolean>()
  const {
    toAddressDetail,
    toTransactionDetail,
    toBlockDetail,
    toKrc20TokenDetail,
  } = useNavagator()

  const [messageApi, contextHolder] = message.useMessage()

  async function search(query: string) {
    if (!query) {
      messageApi.open({
        type: "error",
        content: "Invalid query",
      })
      return
    }

    setLoading(true)
    const searchResult =
      await NetworkDataService.networkControllerSearchNetwork(query)
    setLoading(false)

    switch (searchResult.type) {
      case "address":
        toAddressDetail(searchResult.value)
        break
      case "transaction":
        toTransactionDetail(searchResult.value)
        break
      case "block":
        toBlockDetail(searchResult.value)
        break
      case "token":
        toKrc20TokenDetail(searchResult.value.toUpperCase())
        break
      default:
        messageApi.open({
          type: "error",
          content:
            "We cannot find anything, please double check your query and try again",
        })
        break
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      search(query)
      e.preventDefault()
    }
  }

  return (
    <div className="d-flex justify-content-center ">
      {contextHolder}
      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-3 h-50px my-12 w-75 w-xl-50">
        <div className="row flex-grow-1 mb-lg-0 ">
          <form
            data-kt-search-element="form"
            className="w-100 position-relative"
            autoComplete="off"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
            />

            <input
              type="text"
              className="form-control form-control-flush ps-10 pe-15"
              name="search"
              onChange={(val) => setQuery(val.target.value)}
              onKeyDown={onKeyDown}
              placeholder={intl.formatMessage({ id: "SEARCH_BAR.PLACEHOLDER" })}
              data-kt-search-element="input"
            />

            <div
              className="position-absolute top-50 end-0 translate-middle-y"
              data-kt-search-element="toolbar"
            >
              {loading && (
                <span
                  className="position-absolute top-50 end-0 translate-middle-y lh-0 me-8"
                  data-kt-search-element="spinner"
                >
                  <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
                </span>
              )}
              {!loading && (
                <div
                  data-kt-search-element="preferences-show"
                  className="btn btn-icon w-20px btn-sm btn-active-color-primary me-8"
                  data-bs-toggle="tooltip"
                  onClick={() => {
                    search(query)
                  }}
                >
                  <i className="bi bi-arrow-right-circle fs-2"></i>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { Toolbar }
