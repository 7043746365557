import moment from "moment"
import { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { rowClass, TITLE_SUFFIX } from "../../constants/constants"
import { DEFLATIONARY_TABLE } from "../../constants/deflationaryTable"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { formatUnixTime } from "../../utils/utils"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { Line, LineConfig } from "@ant-design/charts"
import { useAntVTheme } from "../../hooks/useAntVTheme"
import { useRequest } from "ahooks"
import { AnalyticsService, NetworkDataService } from "../../../client"
import { metricsCardClass } from "../dashboard/components/TopMetricsSection"
import { MetricCard } from "../dashboard/components/MetricCard"
import { availableDashboardMetricCards } from "../dashboard/constants"

interface TableDataType {
  date: string
  reward: number
  beforeToday?: boolean
  isNext?: boolean
}

const fotmatDateByTargetDaaScore = (
  currentDaaScore: number,
  targetDaaScore: number
): string => {
  const daaDiff = currentDaaScore - targetDaaScore
  const targetUnix = moment().unix() - daaDiff
  return formatUnixTime(targetUnix)
}

const DeflationaryTableSection = () => {
  const theme = useAntVTheme()

  const [currentDataPoint, setCurrentDataPoint] = useState<
    | {
        date: string
        reward: number
      }
    | undefined
  >(undefined)

  const intl = useIntl()

  const { data: networkInfo, loading } = useRequest(
    NetworkDataService.networkControllerGetNetworkInfo
  )
  const { data: analyticsData } = useRequest(
    AnalyticsService.analyticsControllerGetMetrics
  )

  const tableData = useMemo((): TableDataType[] => {
    const sortedDaaScores = Object.keys(DEFLATIONARY_TABLE)
    return sortedDaaScores
      .slice(0, sortedDaaScores.length - 1) // Move reward up to one row
      .map((daaScoreString: string, index: number) => {
        // This function assmues block time is 1s
        const targetDaaScore = parseInt(daaScoreString)
        const prevDate = fotmatDateByTargetDaaScore(
          networkInfo?.virtualDaaScore || 0,
          parseInt(sortedDaaScores[index - 1])
        )
        const prevReward = sortedDaaScores[index + 1]
        const reward = DEFLATIONARY_TABLE[parseInt(prevReward)]
        const formattedDate = fotmatDateByTargetDaaScore(
          networkInfo?.virtualDaaScore || 0,
          targetDaaScore
        )
        const beforeToday = moment(formattedDate).isBefore(moment())
        const isNext =
          index > 0 && !beforeToday && moment(prevDate).isBefore(moment())

        if (isNext) {
          setCurrentDataPoint({
            date: formattedDate,
            reward,
          })
        }

        return {
          date: formattedDate,
          reward,
          beforeToday,
          isNext,
        }
      })
  }, [networkInfo?.virtualDaaScore])

  const config: LineConfig = useMemo((): LineConfig => {
    return {
      data: tableData,
      theme,
      height: 500,
      slider: {
        start: 0,
        end: 0.2,
      },
      xField: "date",
      xAxis: {
        label: {
          formatter: (v) => moment(v).format("YYYY-MM-DD"),
        },
      },

      yField: "reward",
      yAxis: {
        label: {
          formatter: (v) => `${parseInt(v)} KAS`,
        },
      },
      tooltip: {
        formatter: (v) => {
          return {
            name: intl.formatMessage({
              id: "REDUCED_TO",
            }),
            value: `${v.reward} KAS`,
          }
        },
      },
      annotations: [
        {
          type: "dataMarker",
          position: [
            currentDataPoint?.date || 0,
            currentDataPoint?.reward || 0,
          ],
          text: {
            content: intl.formatMessage({
              id: "NEXT_HALVING",
            }),
            style: {
              textAlign: "center",
            },
          },
        },
      ],
      stepType: "vh",
    }
  }, [currentDataPoint?.date, currentDataPoint?.reward, intl, tableData, theme])

  const allAvailableCards = availableDashboardMetricCards({
    intl,
    data: analyticsData,
  })

  const metricCards = [
    "hashrate",
    "current_reward",
    "next_halving_exact_time",
    "next_halving_reward",
  ]
    .map((key) => allAvailableCards.filter((card) => card.key === key))
    .flat()

  return (
    <>
      <HelmetWrapper
        title={`Kaspa Emission Schedule ${TITLE_SUFFIX}`}
        description={
          "In-depth view of Kaspa's Emission Schedule from launch to 2057, which includes the exact time of block reduction and reduced reward amount."
        }
      />
      <div className={"row"}>
        {metricCards.map((row) => {
          return (
            <div className={metricsCardClass}>
              <MetricCard
                className="bg-body card-xl-stretch mb-xl-8"
                titleClass="text-dark"
                descriptionClass="text-muted"
                iconClass="svg-icon-primary"
                {...row}
              />
            </div>
          )
        })}
      </div>

      <div className={rowClass}>
        <div className="col-xl-12">
          <div className={`card mb-5 mb-xl-8`}>
            <div className="card-header border-0 pt-5 d-flex align-items-center">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  {intl.formatMessage({ id: "TOOLS.DEFLATIONARY_TABLE.TITLE" })}
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "TOOLS.DEFLATIONARY_TABLE.DESCRIPTION",
                  })}
                </span>
              </h3>
            </div>

            <div className="card-body py-3">
              <Line {...config} loading={loading} />
            </div>
          </div>
        </div>

        <div className="mt-0 mt-xl-n2">
          <AdsPlacement placementType="banner" platform={"ads-server"} />
        </div>
      </div>
    </>
  )
}

export { DeflationaryTableSection }
