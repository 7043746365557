/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { Alert, Table, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../client"
import { Card } from "../../components/Card"
import { getTokenRoute, getTransactionRoute } from "../../hooks/navigator"
import { ColumnsType } from "antd/es/table"
import { formatTokenAmount } from "../../utils/tokenUtils"
import { KSPR_AFFILIATE_URL, UNIT_STRING } from "../../constants/constants"
import { AdsAlertBody } from "../../components/partials/AdsAlert"
import {
  formatCash,
  formatUnixTime,
  getTimeDifference,
} from "../../utils/utils"
import { FiatAmount } from "../../components/FiatAmountBadge"
import { TokenIcon } from "../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../components/PriceDiffBadge"
import { PremintPctBadge } from "../../components/partials/PremintPctBadge"
import { TokenMintedProgressiveBar } from "../../components/partials/TokenMintedProgressiveBar"
import { MiniPriceChart } from "../../components/charts/MiniPriceChart"

const Krc20TokenList = ({ tokens }: { tokens?: GetTokenSummaryResponse[] }) => {
  const intl = useIntl()

  const [tableData, setTableData] = useState<GetTokenSummaryResponse[]>()

  const columns: ColumnsType<GetTokenSummaryResponse> = [
    {
      title: intl.formatMessage({ id: "TICKER" }),
      dataIndex: "ticker",
      fixed: "left",
      render: (ticker: string) => {
        return (
          <a
            className="fw-bold d-flex align-items-center"
            href={getTokenRoute(ticker)}
          >
            <TokenIcon ticker={ticker} className="me-2" size="25px" />
            {ticker}
          </a>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.ticker.localeCompare(b.ticker),
    },

    {
      title: intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_AT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { deployedAt } = record
        return (
          <Tooltip title={formatUnixTime(deployedAt / 1000)}>
            <a
              href={getTransactionRoute(record.revealHash)}
              className="text-body text-hover-primary"
            >
              {getTimeDifference(deployedAt, false)}
            </a>
          </Tooltip>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.deployedAt - b.deployedAt,
    },
    {
      title: intl.formatMessage({ id: "TOTAL_SUPPLY" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const ticker = record.ticker || ""
        const { decimal } = record
        return (
          <Tooltip title={formatTokenAmount(ticker, record.maxSupply, decimal)}>
            {formatCash(record.maxSupply / Math.pow(10, decimal))}
          </Tooltip>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.maxSupply - b.maxSupply,
    },

    {
      title: intl.formatMessage({ id: "TOKEN.HEADER.PRE_MINT" }),

      render: (_, record: GetTokenSummaryResponse) => {
        return <PremintPctBadge token={record} />
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
        const aPreMintedPct = (a.preMint / a.maxSupply) * 100
        const bPreMintedPct = (b.preMint / b.maxSupply) * 100
        return aPreMintedPct - bPreMintedPct
      },
      filters: [
        {
          text: intl.formatMessage({ id: "FAIR_LAUNCH" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "LAUNCHED_WITH_PRE_MINT_10_PCT" }),
          value: 10,
        },
        {
          text: intl.formatMessage({ id: "LAUNCHED_WITH_PRE_MINT_50_PCT" }),
          value: 50,
        },
        {
          text: intl.formatMessage({ id: "LAUNCHED_WITH_PRE_MINT_100_PCT" }),
          value: 100,
        },
      ],
      onFilter: (value, record) => {
        const valueNumber = Number(value)
        const preMintedPct = (record.preMint / record.maxSupply) * 100

        if (valueNumber === 100) {
          return preMintedPct === valueNumber
        }

        return preMintedPct <= valueNumber
      },
    },

    {
      title: intl.formatMessage({ id: "MINTED" }),
      render: (_, record: GetTokenSummaryResponse) => {
        return <TokenMintedProgressiveBar token={record} />
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) => {
        const aCurrentSupplyPct = (a.totalMinted / a.maxSupply) * 100
        const bCurrentSupplyPct = (b.totalMinted / b.maxSupply) * 100
        return aCurrentSupplyPct - bCurrentSupplyPct
      },
      filters: [
        {
          text: intl.formatMessage({ id: "ZERO_MINTED" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_10_PCT_MINTED" }),
          value: 10,
        },
        {
          text: intl.formatMessage({ id: "LESS_THAN_50_PCT_MINTED" }),
          value: 50,
        },
        {
          text: intl.formatMessage({ id: "100_PCT_MINTED" }),
          value: 100,
        },
      ],
      onFilter: (value, record) => {
        const valueNumber = Number(value)
        const currentSupplyPct = (record.totalMinted / record.maxSupply) * 100

        if (valueNumber === 100) {
          return currentSupplyPct === valueNumber
        }

        return currentSupplyPct <= valueNumber
      },
    },
    {
      title: intl.formatMessage({ id: "PRICE" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { price, totalMinted, decimal } = record
        const marketCapInKas =
          (price?.floorPrice || 0) * (totalMinted / 10 ** decimal)

        return (
          <div className="d-flex align-items-center">
            <Tooltip
              className="me-2"
              title={
                price &&
                intl.formatMessage(
                  { id: "TOKEN.HEADER.METRICS.MARKET_CAP" },
                  {
                    br: <br />,
                    marketCap: FiatAmount({
                      kaspaAmount: marketCapInKas,
                      precision: 0,
                    }),
                  }
                )
              }
            >
              {price ? `${price.floorPrice.toFixed(8)} ${UNIT_STRING}` : "-"}
            </Tooltip>

            {price?.change24h ? (
              <PriceDiffBadge diff={price.change24h} precision={0} />
            ) : null}
          </div>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        (a.price?.floorPrice || 0) - (b.price?.floorPrice || 0),
      filters: [
        {
          text: intl.formatMessage({ id: "HAS_PRICE" }),
          value: true,
        },
        {
          text: intl.formatMessage({ id: "NO_PRICE" }),
          value: false,
        },
      ],
      onFilter: (value, record) => {
        if (value === true) {
          return record.price !== undefined && record.price.floorPrice > 0
        }

        return record.price?.floorPrice === 0 || !record.price
      },
    },
    {
      title: intl.formatMessage({ id: "LAST_7_DAYS" }),
      width: 140,
      render: (_, record: GetTokenSummaryResponse) => {
        const { priceHistory, price } = record

        return (
          <MiniPriceChart
            data={priceHistory?.map((p) => p.p) || []}
            color={
              price?.change24h && price.change24h > 0 ? "#47BE7D" : "#F1416C"
            }
          />
        )
      },
    },
    {
      title: intl.formatMessage({ id: "MINTED_COUNT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { mintTotal } = record

        return (
          <div className="d-flex align-items-center">
            {mintTotal.toLocaleString()}
          </div>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.mintTotal - b.mintTotal,
    },
    {
      title: intl.formatMessage({ id: "HOLDERS_COUNT" }),
      render: (_, record: GetTokenSummaryResponse) => {
        const { holderTotal } = record

        return (
          <div className="d-flex align-items-center">
            {holderTotal.toLocaleString()}
          </div>
        )
      },
      sorter: (a: GetTokenSummaryResponse, b: GetTokenSummaryResponse) =>
        a.holderTotal - b.holderTotal,
    },

    {
      title: intl.formatMessage({ id: "ACTIONS" }),
      render: (_, record: GetTokenSummaryResponse) => {
        return (
          <div className="d-flex align-items-center">
            <Tooltip
              title={intl.formatMessage(
                { id: "MINT_TICKER" },
                { ticker: record.ticker }
              )}
            >
              <a
                href={KSPR_AFFILIATE_URL}
                className="btn btn-icon btn-secondary btn-sm me-4"
                target="_blank"
              >
                <i className="bi bi-minecart fs-4"></i>
              </a>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage(
                { id: "TRADE_TICKER" },
                { ticker: record.ticker }
              )}
            >
              <a
                href={KSPR_AFFILIATE_URL}
                className="btn btn-icon btn-secondary btn-sm"
                target="_blank"
              >
                <i className="bi bi-currency-exchange fs-4"></i>
              </a>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const onTableSearch = (val: string) => {
    if (val) {
      setTableData(
        tokens?.filter((row) =>
          row.ticker.toLowerCase().includes(val.toLowerCase())
        )
      )
    } else {
      setTableData(tokens)
    }
  }

  useEffect(() => {
    setTableData(tokens)
  }, [tokens])

  return (
    <div className="col-xl-12" id="top-addresses">
      <Card
        title={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.TITLE`,
        })}
        description={intl.formatMessage({
          id: `TOKEN.HEADER.TOKEN_LIST.DESCRIPTION`,
        })}
        toolbar={
          <Search
            placeholder={intl.formatMessage({ id: "SEARCH_FOR_TOKEN_TICKER" })}
            allowClear
            onChange={(e) => onTableSearch(e.target.value)}
          />
        }
        body={
          <div>
            <Alert
              className="mb-4 mt-n2"
              message={<AdsAlertBody />}
              type="info"
            />

            <Table
              size="middle"
              loading={!tableData}
              columns={columns}
              dataSource={tableData}
              scroll={{ x: "max-content" }}
              pagination={{ defaultPageSize: 20 }}
            />
          </div>
        }
      />
    </div>
  )
}

export { Krc20TokenList }
