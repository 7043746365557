import { Table } from "antd"
import { useIntl } from "react-intl"
import { Card } from "../../components/Card"
import { formatKaspaAmount } from "../../utils/utils"
import { donors } from "./donors"

export const DonorsWrapper = () => {
  const intl = useIntl()

  const columns = [
    {
      title: intl.formatMessage({ id: "DONOR_LIST.TABLE.NAME" }),
      dataIndex: "name",
    },
    {
      title: intl.formatMessage({ id: "DONOR_LIST.TABLE.AMOUNT" }),
      dataIndex: "amount",
      render: (amount: number) => formatKaspaAmount(amount * 1e8),
    },
  ]

  return (
    <div className="col-xl-12">
      <Card
        title={intl.formatMessage({
          id: "DONOR_LIST.NAME",
        })}
        body={
          <>
            <Table
              columns={columns}
              dataSource={donors}
              scroll={{ x: "max-content" }}
              pagination={false}
            />
            <div className="alert alert-primary d-flex align-items-center mt-10">
              <div className="d-flex flex-column">
                <span className="text-dark">
                  {intl.formatMessage(
                    { id: "DONOR_LIST.TOOLTIP" },
                    {
                      br: <br />,
                      contactLink: (
                        <a
                          className="text-primary"
                          href="https://t.me/khalil101101"
                          target="_blank"
                        >
                          {intl.formatMessage({
                            id: "DONATION.MODAL.TOOLTIP.MESSAGE.CONTACT_LINK",
                          })}
                        </a>
                      ),
                    }
                  )}
                </span>
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}
