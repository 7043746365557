/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from "react-intl"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { getBlockRoute } from "../../../hooks/navigator"
import { KaspadBlockType } from "../../../utils/types"
import {
  formatKaspaAmount,
  formatUnixTime,
  getTotalTransactionAmount,
  kaspaAmountToInt,
  shortenHash,
} from "../../../utils/utils"
import { ReactNode } from "react"
import { parsePayload } from "../../../utils/ben32"

type Props = {
  className?: string
  blocks?: KaspadBlockType[]
}

const getBlockMinerVersion = (payload: string[]): string | undefined => {
  const minerInfo = payload[1]
  const version = minerInfo.split("/")
  if (version.length < 2) {
    return undefined
  }
  return version[0]
}

const MinerTag = ({ payload }: { payload: string[] }) => {
  const minerVersion = getBlockMinerVersion(payload)
  const rustVersion = "0.13"
  if (!minerVersion) {
    return null
  }

  let className = "badge-light-success"
  let badgeText = "Go"
  if (minerVersion >= rustVersion) {
    badgeText = "Rusty"
    className = "badge-light-warning"
  }

  return <span className={`badge ${className}`}>{badgeText}</span>
}

export const LiveBlocksTable: React.FC<Props> = ({ blocks, className }) => {
  const intl = useIntl()

  const blockToRow = (block: KaspadBlockType) => {
    const totalAmount = block.transactions.reduce((acc, cur) => {
      return acc + getTotalTransactionAmount(cur.outputs)
    }, 0)
    const payload = parsePayload(block.transactions[0]?.payload)

    return {
      daaScore: parseInt(block.header.daaScore),
      blueScore: parseInt(block.verboseData.blueScore),
      timestamp: block.header.timestamp,
      hash: block.verboseData.hash,
      txCount: block.transactions.length,
      totalAmount,
      payload,
    }
  }

  const Link = ({
    children,
    hash,
    className = "",
  }: {
    children: ReactNode
    hash: string
    className?: string
  }) => {
    return (
      <a
        className={`${className} text-dark text-hover-primary fs-7`}
        href={getBlockRoute(hash)}
      >
        {children}
      </a>
    )
  }
  return (
    <div className={`table-responsive ${className}`}>
      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3">
        <thead>
          <tr className="fw-bold text-muted">
            <th className="w-25">{intl.formatMessage({ id: "DAA_SCORE" })}</th>
            <th className="w-25">{intl.formatMessage({ id: "HASH" })}</th>
            <th className="w-10"></th>
            <th className="w-40">
              {intl.formatMessage({ id: "AMOUNT_TRANSACTED" })}
            </th>
          </tr>
        </thead>

        <tbody>
          {blocks
            ?.sort((a, b) => {
              return b?.header?.timestamp - a?.header?.timestamp
            })
            ?.map((block, index) => {
              const rowData = blockToRow(block)
              return (
                <tr key={`block-row-${index}`}>
                  <td>
                    <Link
                      children={rowData.daaScore.toLocaleString()}
                      hash={rowData.hash}
                    />
                    <span className="text-muted fw-semibold text-muted d-block fs-7">
                      {formatUnixTime(rowData.timestamp / 1e3, true)}
                    </span>
                  </td>
                  <td>
                    <Link
                      children={shortenHash(rowData.hash)}
                      hash={rowData.hash}
                      className="me-2"
                    />
                  </td>
                  <td>
                    <div className="mb-1">
                      <MinerTag payload={rowData.payload} />
                    </div>
                    <div className={`badge badge-light`}>
                      {intl.formatMessage(
                        { id: "TX_COUNT_WITH_NUMBER" },
                        { number: rowData.txCount.toLocaleString() }
                      )}
                    </div>
                  </td>
                  <td>
                    <Link
                      children={
                        <>
                          <span className="me-2">
                            {formatKaspaAmount(rowData.totalAmount, 2)}
                          </span>
                          {"\n"}
                          <FiatAmountBadge
                            className="mx-0"
                            kaspaAmount={kaspaAmountToInt(rowData.totalAmount)}
                          />
                        </>
                      }
                      hash={rowData.hash}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
