export const rowClass = "row g-xl-8 mb-5 mb-xl-0"

export const WHALE_MOVEMENT_FILTERED_ADDRESS = [
  "kaspa:qpjunp39ssazf4rzfxxu0hd35xggfxn6lq0ls9u9q6peevzcmcv4xmv9q4njd",
  "kaspa:qqetp7ct8kqss99fxmymyz5t3fezppxp0t58wl6pawp27elqd46uudme00cl0",
  "kaspa:qpzpfwcsqsxhxwup26r55fd0ghqlhyugz8cp6y3wxuddc02vcxtjg75pspnwz",
  "kaspa:qr590lsl5fhz3mj3frqnx55tqc9qvqap43v0v5q6j3kz2yphmzgmw4q85l3fy",
  "kaspa:qzlg6v4pw3xf8r7zezjja35atmd7jspz7du4sfazchtr3k7ejc0rg30faemt5",
]

export const DONATION_ADDRESS =
  "kaspa:qrvsfvasrlt00fnw934rn25fw282fezysyyqkhzld46keggsg7pjxg59tkk96"

export const PRIMARY_COLOR = "#3addbe"
export const DARK_BG_COLOR = "#151520"

export const DEFAULT_TITLE = "Kaspa Block Explorer - Kas.fyi"
export const TITLE_SUFFIX = "- Kas.fyi"
export const DEFAULT_DESCRIPTION =
  "Kas.fyi is an all-in-one block explorer for Kaspa, providing easy access to transaction information and real-time network data."
export const DEFAULT_KEYWORDS = [
  "kaspa",
  "kas",
  "ksapa explorer",
  "kas explorer",
  "kaspa block explorer",
  "kaspa wallet",
  "kaspa rich list",
  "kaspa address",
  "kaspa block",
  "kaspa transaction",
  "kaspa bps",
  "kaspa tps",
  "kaspa mining",
  "kaspa whale",
  "kaspa price",
  "kaspa stats",
  "kaspa chart",
]

export const CONFIRMED_COUNT = 60 * 60 // 1 hours

export const METRICS_CARD_POOLING_INTERVAL = 5000 // 5 seconds

export const ALTERNATIVE_EXPLORER_URL_BLOCK =
  "https://explorer.kaspa.org/blocks/"
export const ALTERNATIVE_EXPLORER_URL_TX = "https://explorer.kaspa.org/txs/"
export const ALTERNATIVE_EXPLORER_URL_ADDRESS =
  "https://explorer.kaspa.org/addresses/"
export const KSPR_AFFILIATE_URL = "https://t.me/kspr_home_bot?start=WdRcvw"

export const UNIT_STRING = "𐤊AS"
