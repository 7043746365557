export const formatTokenAmount = (
  ticker: string,
  amount: number,
  decimal: number,
  precision: number = 2
) => {
  if (!amount) return `0 ${ticker}`

  const amountAfterDecimal = (amount as number) / 10 ** (decimal as number)
  return (
    amountAfterDecimal.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
    }) + ` ${ticker}`
  )
}
