import { useRequest } from "ahooks"
import { useParams } from "react-router-dom"
import { BlocksService } from "../../../client"
import { rowClass, TITLE_SUFFIX } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { BlockHeader } from "./BlockHeader"
import { BlockTransactions } from "./BlockTransactions"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { useState } from "react"

const BlockWrapper = () => {
  let { hash = "" } = useParams()
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const { data: blockInfo, loading: infoLoading } = useRequest(
    async () => BlocksService.blocksControllerGetBlockInfo(hash),
    { retryCount: 5 }
  )

  const { data: blockTransactions, loading: txLoading } = useRequest(
    async () => BlocksService.blocksControllerGetBlockTransaction(hash),
    { retryCount: 5 }
  )

  return (
    <div className={rowClass}>
      <HelmetWrapper
        title={`Block ${hash} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa block ${hash} with ${blockTransactions?.length} transactions`}
      />
      <div className={"col-xl-4"}>
        <BlockHeader block={blockInfo} loading={infoLoading} />
      </div>
      <div className={"col-xl-8"}>
        <BlockTransactions
          transactions={blockTransactions}
          loading={txLoading}
        />
        <AdsPlacement placementType="banner" platform={adsPlatform} />
      </div>
    </div>
  )
}

export { BlockWrapper }
