import { useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { useThemeMode } from "../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"

export const TokenIcon = ({
  ticker,
  className,
  size = "20px",
}: {
  ticker: string
  className?: string
  size?: string
}) => {
  const [imageError, setImageError] = useState(false)
  const { mode } = useThemeMode()

  const handleImageError = () => {
    setImageError(true)
  }

  const imageUrl = imageError
    ? toAbsoluteUrl(
        mode === "dark"
          ? "/media/svg/files/blank-image-dark.svg"
          : "/media/svg/files/blank-image.svg"
      )
    : toAbsoluteUrl(`/assets/token-icons/${ticker.toUpperCase()}.jpg`)

  useEffect(() => {
    setImageError(false)
  }, [ticker])

  return (
    <div className={`symbol symbol-circle symbol-${size} ${className}`}>
      <div
        className="symbol-label"
        style={{
          backgroundImage: `url('${imageUrl}')`,
        }}
      >
        <img
          src={imageUrl}
          alt={ticker}
          onError={handleImageError}
          style={{ display: "none" }}
        />
      </div>
    </div>
  )
}
