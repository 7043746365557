/* eslint-disable react/jsx-no-target-blank */
import { useSize } from "ahooks"
import InnerHTML from "dangerously-set-html-content"
import { useRef } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

declare global {
  interface Window {
    plausible: any
  }
}

const API_SERVER = process.env.REACT_APP_API_SERVER

type PlacementType = "banner" | "box"
export type PlatformType =
  | "coinzilla"
  | "cointraffic"
  | "adsense"
  | "ads-server"

const trackEvent = (eventName: string, props?: any) => {
  window.plausible && window.plausible(eventName, { props })
}

const CoinzillaAdsPlacement = ({
  placementType,
}: {
  placementType: PlacementType
}) => {
  if (placementType === "banner") {
    return (
      <div className="d-flex justify-content-center">
        <InnerHTML
          html={`
            <script async src="https://coinzillatag.com/lib/display.js"></script>
            <div class="coinzilla" data-zone="C-1806492ff233b811396"></div>
            <script>
              window.coinzilla_display = window.coinzilla_display || [];
              var c_display_preferences = {};
              c_display_preferences.zone = "1806492ff233b811396";
              c_display_preferences.width = "728";
              c_display_preferences.height = "90";
              coinzilla_display.push(c_display_preferences);
            </script>
          `}
        />
      </div>
    )
  } else if (placementType === "box") {
    return (
      <InnerHTML
        html={`
          <script async src="https://coinzillatag.com/lib/display.js">
          </script>
          <div class="coinzilla" data-zone="C-1456492ff233b2d8452"></div>
          <script>
            window.coinzilla_display = window.coinzilla_display || [];
            var c_display_preferences = {};
            c_display_preferences.zone = "1456492ff233b2d8452";
            c_display_preferences.width = "300";
            c_display_preferences.height = "250";
            coinzilla_display.push(c_display_preferences);
          </script>
        `}
      />
    )
  } else {
    return null
  }
}

const CoinrafficAdsPlacement = ({
  placementType,
}: {
  placementType: PlacementType
}) => {
  if (placementType === "banner") {
    return (
      <div className="d-flex justify-content-center">
        {/* desktop size */}
        <span id="ct_c11PkKAg2Er"></span>
        {/* mobile size */}
        <span id="ct_celcD29ypR1"></span>
      </div>
    )
  } else if (placementType === "box") {
    return (
      <>
        {/* desktop size */}
        <span id="ct_cQS7he8nTrT"></span>
        {/* mobile size */}
        <span id="ct_cLmDvrhipJ8"></span>
      </>
    )
  } else {
    return null
  }
}

const AdSenseAdsPlacement = ({
  placementType,
}: {
  placementType: PlacementType
}) => {
  if (placementType === "banner") {
    return (
      <div className="d-flex justify-content-center">
        <InnerHTML
          html={`
            <ins class="adsbygoogle adsense-banner"
              style="display:inline-block;"
              data-ad-client="ca-pub-1963873307882205"
              data-ad-slot="1535971851"
            </ins>
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          `}
        />
      </div>
    )
  } else {
    return null
  }
}

const AdsServerPlacement = ({
  placementType,
  width,
}: {
  placementType: PlacementType
  width?: number
}) => {
  if (width === undefined) {
    return null
  }

  const isMobile = width < 768
  const prefix = `${API_SERVER}/sponsor`

  if (placementType === "banner") {
    const id = isMobile ? "banner-mobile" : "banner"
    return (
      <div className="d-flex justify-content-center">
        <a
          href={`${prefix}/${id}/redirect`}
          target="_blank"
          onClick={() => trackEvent("click-ads", { size: placementType })}
        >
          <LazyLoadImage
            alt=""
            height={isMobile ? 100 : 90}
            width={isMobile ? 320 : 728}
            src={`${prefix}/${id}/view`}
            onLoad={() => trackEvent("view-ads", { size: placementType })}
          />
        </a>
      </div>
    )
  } else if (placementType === "box") {
    return (
      <div className="d-flex justify-content-center">
        <a
          href={`${prefix}/box/redirect`}
          target="_blank"
          onClick={() => trackEvent("click-ads", { size: placementType })}
        >
          <LazyLoadImage
            alt=""
            width={300}
            height={250}
            src={`${prefix}/box/view`}
            onLoad={() => trackEvent("view-ads", { size: placementType })}
          />
        </a>
      </div>
    )
  } else {
    return null
  }
}

export const AdsPlacement = ({
  platform,
  placementType,
}: {
  placementType: PlacementType
  platform: PlatformType
}) => {
  const ref = useRef(null)
  const size = useSize(ref)

  switch (platform) {
    case "coinzilla":
      return <CoinzillaAdsPlacement placementType={placementType} />
    case "cointraffic":
      return <CoinrafficAdsPlacement placementType={placementType} />
    case "adsense":
      return <AdSenseAdsPlacement placementType={placementType} />
    case "ads-server":
      return (
        <div ref={ref}>
          <AdsServerPlacement
            placementType={placementType}
            width={size?.width}
          />
        </div>
      )
    default:
      return null
  }
}
