/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import { Transaction } from "../../../client"
import { TransactionSide } from "./TransactionSide"

interface TransactionBodyProps {
  transaction?: Transaction
  loading?: boolean
}

const TransactionBody: React.FC<TransactionBodyProps> = ({
  transaction,
  loading,
}) => {
  const intl = useIntl()

  return (
    <div className={"card mb-5 mb-xl-10"}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({ id: "TRANSACTION.DETAILS.TITLE" })}
          </h3>
        </div>
      </div>

      <div className={`card-body mt-1 border-top p-0 d-md-flex`}>
        <TransactionSide
          type="out"
          className={`card-xl-stretch mb-5 mb-xl-8 col-md-6 col-sm-12 ${
            loading && "overlay overlay-block"
          }`}
          txs={transaction?.inputs}
          loading={loading}
          showTrace
        />
        <TransactionSide
          type="in"
          className={`card-xl-stretch mb-5 mb-xl-8 col-md-6 col-sm-12 ${
            loading && "overlay overlay-block"
          }`}
          txs={transaction?.outputs}
          loading={loading}
          showTrace
        />
      </div>
    </div>
  )
}

export { TransactionBody }
