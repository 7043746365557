import { ReactNode } from "react"
import { LoadingOverlay } from "../pages/dashboard/components/LoadingOverlay"

interface CardProps {
  title?: string | ReactNode
  description?: string | ReactNode
  toolbar?: ReactNode
  body?: ReactNode
  className?: string
  loading?: boolean
}

export const Card = ({
  title,
  description,
  toolbar,
  body,
  className = "",
  loading = false,
}: CardProps) => {
  return (
    <div className={`card ${className} ${loading && "overlay overlay-block"}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 d-flex align-items-center">
            {title}
          </span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {description}
          </span>
        </h3>
        <div className="card-toolbar">{toolbar}</div>
      </div>

      <div className="card-body py-3">{body}</div>

      {loading && <LoadingOverlay />}
    </div>
  )
}
