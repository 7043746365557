/* eslint-disable jsx-a11y/anchor-is-valid */
import { Segmented } from "antd"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { Transaction } from "../../../../client"
import { MonitorAddressModal } from "../../../components/MonitorAddressModal"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { EmptyState } from "../../../components/TableEmptyState"
import { TransactionRow } from "./TransactionRow"
import { SwapOutlined, TransactionOutlined } from "@ant-design/icons"
import { TokenTransactionsWrapper } from "../../token/TokenTransactionsWrapper"
import { Krc20UIEnabled } from "../../../utils/feature"
import { SimplePagination } from "../../../components/partials/SimplePagination"

export enum TransactionViewType {
  Transaction = "TRANSACTION",
  TokenTransfer = "TOKEN_TRANSFER",
  TokenHolders = "TOKEN_HOLDERS",
}

interface AddressTransactionsProps {
  transactions?: Transaction[]
  currentPage?: number
  currentTokenPage?: number
  address: string
  loading?: boolean
  error?: Error
  totalPage: number
  onPreviousPage: () => void
  onNextPage: () => void
  transactionsCardRef: React.RefObject<HTMLDivElement>
}
interface AddressTransactionWrapperProps {
  transactions?: Transaction[]
  address: string
  loading: boolean
  error?: Error
  totalPage: number
  onPreviousPage: () => void
  onNextPage: () => void
  transactionsCardRef: React.RefObject<HTMLDivElement>
}

const AddressTransactionWrapper: React.FC<AddressTransactionWrapperProps> = ({
  transactions,
  address,
  loading,
  error,
  totalPage,
  onPreviousPage,
  onNextPage,
  transactionsCardRef,
}) => {
  const { showTxnDetail } = useGlobalData()
  const [currentPage, setCurrentPage] = useState(1)

  const clickedPreviousPage = () => {
    setCurrentPage(currentPage - 1)
    onPreviousPage()
  }
  const clickedNextPage = () => {
    setCurrentPage(currentPage + 1)
    onNextPage()
  }

  return (
    <>
      {!error &&
        transactions?.map((tx, index) => (
          <TransactionRow
            key={`tx-row-${index}`}
            tx={tx}
            address={address}
            traceEnabled={showTxnDetail}
            loading={loading}
          />
        ))}

      {!loading && error && <EmptyState textId="TRANSACTION.LIST.ERROR" />}
      {!loading && transactions?.length === 0 && (
        <EmptyState textId="TRANSACTION.LIST.NOT_FOUND" />
      )}

      <div className="m-5">
        <SimplePagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPreviousPage={clickedPreviousPage}
          onNextPage={clickedNextPage}
          addressTnxLoading={loading}
          transactionsCardRef={transactionsCardRef}
        />
      </div>
    </>
  )
}

const TraceToggle = () => {
  const intl = useIntl()
  const { showTxnDetail, toggleShowTxnDetail } = useGlobalData()

  return (
    <div className="form-check form-check-solid form-switch fv-row d-flex">
      <input
        className="form-check-input w-30px h-20px me-2"
        type="checkbox"
        id="allowmarketing"
        defaultChecked={true}
        onChange={toggleShowTxnDetail}
        checked={showTxnDetail}
      />
      <label className="form-check-label">
        {intl.formatMessage({ id: "ADDRESS.TRANSACTION.SHOW_DETAILS" })}
      </label>
    </div>
  )
}

const AddressTransactions: React.FC<AddressTransactionsProps> = ({
  transactions,
  address,
  loading,
  error,
  totalPage,
  onPreviousPage,
  onNextPage,
  transactionsCardRef,
}) => {
  const intl = useIntl()

  const stagingTransactions = loading ? [...Array(1)] : transactions

  const [viewType, setViewType] = useState<TransactionViewType>(
    TransactionViewType.Transaction
  )

  return (
    <div className={"card mb-5 mb-xl-10"} style={{ minHeight: "10vh" }}>
      <MonitorAddressModal address={address} />
      <div className="card-header border-bottom">
        <div className="card-title m-0 d-flex align-items-center">
          <Segmented
            defaultValue={TransactionViewType.Transaction}
            onChange={(value) => {
              setViewType(value as TransactionViewType)
            }}
            options={[
              {
                label: intl.formatMessage({
                  id: "ADDRESS.TRANSACTION.TRANSACTION_HISTORY",
                }),
                value: TransactionViewType.Transaction,
                icon: <TransactionOutlined />,
              },
              ...(Krc20UIEnabled
                ? [
                    {
                      label: intl.formatMessage({
                        id: "ADDRESS.TRANSACTION.TOKEN_TRANSFER",
                      }),
                      value: TransactionViewType.TokenTransfer,
                      icon: <SwapOutlined />,
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <div className="card-toolbar">
          <TraceToggle />
        </div>
      </div>

      {viewType === TransactionViewType.Transaction ? (
        <AddressTransactionWrapper
          transactions={stagingTransactions}
          address={address}
          loading={loading || !transactions}
          error={error}
          totalPage={totalPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          transactionsCardRef={transactionsCardRef}
        />
      ) : (
        <TokenTransactionsWrapper address={address} hideHeader />
      )}
    </div>
  )
}

export { AddressTransactions }
