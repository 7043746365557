import moment from "moment"
import {
  Transaction,
  TransactionInput,
  TransactionOutput,
} from "../../../client"

export const calculateTransactionFee = (
  inputs?: TransactionInput[],
  outputs?: TransactionOutput[]
) => {
  if (!inputs || inputs.length === 0) return 0

  const inTotal = (inputs || []).reduce((acc, input) => {
    return acc + (Number(input.previousOutput?.amount) || 0)
  }, 0)
  const outTotal = (outputs || []).reduce((acc, output) => {
    return acc + (Number(output.amount) || 0)
  }, 0)

  const diff = inTotal - outTotal
  return diff > 0 ? diff : 0
}

export const isLegacyTransaction = (tx?: Transaction) => {
  return tx?.blockTime && tx?.blockTime <= 1653480022260 ? true : false
}

export const calculateAcceptingTime = (
  tx?: Transaction
): number | undefined => {
  if (!tx?.blockTime || !tx?.acceptingBlock?.timestamp) {
    return undefined
  }

  const txTime = tx.blockTime
  const acceptingTime = moment(tx.acceptingBlock.timestamp).valueOf()

  return acceptingTime - txTime // in milliseconds
}

export const isCompoundTransaction = (tx?: Transaction) => {
  if (!tx || tx.inputs?.length === 0) return false

  // Gather all addresses from inputs and outputs
  const addresses = [
    ...tx?.inputs.map((input) => input.previousOutput?.scriptPublicKeyAddress || "UNKNOWN"),
    ...tx?.outputs.map((output) => output.scriptPublicKeyAddress),
  ].filter((address) => address)

  // Check if there are more than 1 unique address
  const uniqueAddresses = new Set(addresses)
  return uniqueAddresses.size === 1
}
