/* eslint-disable react/jsx-no-target-blank */
import { Tooltip } from "antd"
import { AddressTag as AddressTagModel } from "../../../client"
import { useIntl } from "react-intl"

interface TagProps {
  className: string
  tag: AddressTagModel
}

export const RegularTag = ({ className, tag }: TagProps) => {
  const linkIcon = tag.link && (
    <i className="fa-solid fa-arrow-up-right-from-square ms-2 fs-7 text-primary"></i>
  )
  return (
    <a
      className={`badge mh-20px badge-light-primary ${className}`}
      href={tag.link}
      target="_blank"
    >
      {tag?.name}
      {linkIcon}
    </a>
  )
}

export const RankTag = ({ className, tag }: TagProps) => {
  const intl = useIntl()

  return (
    <Tooltip title={intl.formatMessage({ id: "ADDRESS.DETAIL.RANK.TOOLTIP" })}>
      {tag.link ? (
        <a
          className={`badge mh-20px badge-light ${className}`}
          href={tag.link}
          target="_blank"
        >
          {tag?.name}
        </a>
      ) : (
        <div className={`badge mh-20px badge-light ${className}`}>
          {tag?.name}
        </div>
      )}
    </Tooltip>
  )
}

export const AddressTag = ({
  className,
  tag,
}: {
  className: string
  tag: AddressTagModel
}) => {
  switch (tag.type) {
    case "rank":
      return <RankTag className={className} tag={tag} />
    default:
      return <RegularTag className={className} tag={tag} />
  }
}
