/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NetworkHealthResponse } from '../models/NetworkHealthResponse';
import type { NetworkInfoResponse } from '../models/NetworkInfoResponse';
import type { SearchNetworkResponse } from '../models/SearchNetworkResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NetworkDataService {

    /**
     * Return Kaspa network info
     * @returns NetworkInfoResponse
     * @throws ApiError
     */
    public static networkControllerGetNetworkInfo(): CancelablePromise<NetworkInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/network/info',
        });
    }

    /**
     * Search for entity against Kaspa network, it could be address, transction or block
     * @param query Query to search for, could be address, transaction id or block hash
     * @returns SearchNetworkResponse
     * @throws ApiError
     */
    public static networkControllerSearchNetwork(
        query: string,
    ): CancelablePromise<SearchNetworkResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/network/search',
            query: {
                'query': query,
            },
        });
    }

    /**
     * Get backend health
     * @returns NetworkHealthResponse
     * @throws ApiError
     */
    public static networkControllerGetNetworkHealth(): CancelablePromise<NetworkHealthResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/network/health',
        });
    }

    /**
     * Get indexer health
     * @returns NetworkHealthResponse
     * @throws ApiError
     */
    public static networkControllerGetIndexerHealth(): CancelablePromise<NetworkHealthResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/network/indexer_health',
        });
    }

}
