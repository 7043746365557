/* eslint-disable react/jsx-no-target-blank */
import { KSPR_AFFILIATE_URL } from "../../constants/constants"
import { useIntl } from "react-intl"

export const AdsAlertBody = () => {
  const intl = useIntl()

  return (
    <div className="d-flex align-items-center">
      <span className="badge badge-danger me-3 me-md-2">HOT</span>
      <span className="fw-semibold">
        {intl.formatMessage(
          { id: "DASHBOARD.HEADER.NOTIFICATION_BAR.TITLE" },
          {
            ksprBotLink: (
              <a href={KSPR_AFFILIATE_URL} target="_blank">
                KSPR Bot
              </a>
            ),
          }
        )}
      </span>
    </div>
  )
}
