/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse } from "../../../../client"
import { Skeleton, Tooltip } from "antd"
import { formatUnixTime, getTimeDifference } from "../../../utils/utils"
import { MintOnKSPRBotButton } from "../../../components/partials/MintOnKSPRBotButton"
import { TradeOnKSPRBotButton } from "../../../components/partials/TradeOnKSPRBotButton"
import { UNIT_STRING } from "../../../constants/constants"
import { FiatAmount } from "../../../components/FiatAmountBadge"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { PriceDiffBadge } from "../../../components/PriceDiffBadge"
import { PremintPctBadge } from "../../../components/partials/PremintPctBadge"
import { TokenMintedProgressiveBar } from "../../../components/partials/TokenMintedProgressiveBar"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break fs-6"
const valueClass =
  "fw-semibold text-dark text-break fs-6 d-flex align-items-center"

const renderCell = (label: string, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap">
      <div>
        <div className={`d-flex align-items-center mb-1`}>
          <span className={labelClass}>{label}</span>
        </div>
        {value}
      </div>
    </div>
  )
}

interface TokenHeaderProps {
  token?: GetTokenSummaryResponse
  loading?: boolean
}

const TokenHeader: React.FC<TokenHeaderProps> = ({ token, loading }) => {
  const intl = useIntl()

  const ticker = token?.ticker || ""
  const price = token?.price?.floorPrice || 0
  const change24h = token?.price?.change24h || 0
  const marketCapInKas =
    (price || 0) * ((token?.totalMinted || 0) / 10 ** (token?.decimal || 1))

  return (
    <div className={`card mb-6`}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0 d-flex align-items-center">
            <TokenIcon ticker={ticker} className="me-2" size="20px" />
            {intl.formatMessage(
              { id: "TOKEN.HEADER.TOKEN_DETAILS" },
              { ticker }
            )}
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              intl.formatMessage({ id: "FLOOR_PRICE" }),
              <div className={valueClass}>
                <Tooltip
                  title={intl.formatMessage(
                    {
                      id: "TOKEN.HEADER.FLOOR_PRICE_TOOLTIP",
                    },
                    {
                      marketCap: FiatAmount({ kaspaAmount: marketCapInKas }),
                    }
                  )}
                >
                  <div className="d-flex align-items-center">
                    <span className="me-2">
                      {price.toFixed(8)} {UNIT_STRING} / {token?.ticker}
                    </span>
                    {change24h ? (
                      <PriceDiffBadge diff={change24h} precision={1} />
                    ) : null}
                  </div>
                </Tooltip>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TOTAL_MINTED" }),
              <div className={valueClass}>
                {token && (
                  <div className="w-100">
                    <TokenMintedProgressiveBar token={token} />
                  </div>
                )}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.PRE_MINT" }),
              <div className={valueClass}>
                {token && <PremintPctBadge token={token} />}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.STATUS" }),
              <div className={valueClass}>
                <span className="badge badge-light-success">
                  {token?.status}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.DEPLOYED_AT" }),
              <div className={valueClass}>
                {formatUnixTime(
                  token?.deployedAt ? token?.deployedAt / 1e3 : 0
                )}
                <span className="badge badge-light ms-2">
                  {getTimeDifference(token?.deployedAt || 0, false)}
                </span>
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.TRANSFER_COUNT" }),
              <div className={valueClass}>
                {token?.transferTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.HOLDERS_COUNT" }),
              <div className={valueClass}>
                {token?.holderTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.MINT_COUNT" }),
              <div className={valueClass}>
                {token?.mintTotal.toLocaleString()}
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "TOKEN.HEADER.ACTIONS" }),
              <div className="d-flex flex-column">
                {token?.status !== "finished" && (
                  <div className="row mb-4">
                    <MintOnKSPRBotButton ticker={ticker} />
                  </div>
                )}

                <div className="row">
                  <TradeOnKSPRBotButton ticker={ticker} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { TokenHeader }
