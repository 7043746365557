/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Transaction } from "../../../client"
import {
  formatKaspaAmount,
  formatUnixTime,
  getRandomAdsPlatfrom,
  getTimeDifference,
  getTotalTransactionAmount,
  kaspaAmountToInt,
} from "../../utils/utils"
import { TransactionStatusTag } from "../../components/TransactionStatusTag"
import { FiatAmountBadge } from "../../components/FiatAmountBadge"
import { getBlockRoute } from "../../hooks/navigator"
import { PasteIcon } from "../../components/PasteIcon"
import { BlockHashBullet } from "../../components/BlockHashBullet"
import { useIntl } from "react-intl"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { Skeleton, Tooltip } from "antd"
import { ALTERNATIVE_EXPLORER_URL_TX } from "../../constants/constants"
import {
  calculateAcceptingTime,
  calculateTransactionFee,
  isLegacyTransaction,
} from "./utils"

const Space = () => <div className="my-5"></div>

const labelClass = "fw-semibold text-muted text-break mb-1 fs-6"
const valueClass = "fw-semibold text-dark text-break fs-6"

const DashSeparator = () => (
  <div className="separator separator-dashed my-6"></div>
)

const renderCell = (label: string, value: React.ReactNode) => {
  return (
    <div className="d-flex flex-wrap align-items-center">
      <div>
        <div className={`${labelClass}`}>{label}</div>
        {value}
      </div>
    </div>
  )
}

interface TransactionHeaderProps {
  tx?: Transaction
  loading?: boolean
}

const TransactionHeader: React.FC<TransactionHeaderProps> = ({
  tx,
  loading,
}) => {
  const intl = useIntl()
  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const transactedAmount = getTotalTransactionAmount(tx?.outputs)
  const transactionFee = calculateTransactionFee(tx?.inputs, tx?.outputs)
  const acceptedInTime = calculateAcceptingTime(tx)
  const isCoinbase = tx?.inputs?.length === 0
  const mass = tx?.mass

  return (
    <div className={"card mb-5"}>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">
            {intl.formatMessage({ id: "TRANSACTION.OVERVIEW.TITLE" })}
            <Tooltip title="To explorer.kaspa.org">
              <a
                role="button"
                className="fa-solid fa-arrow-up-right-from-square text-hover-primary fs-7 text-dark ms-3 mt-1"
                href={`${ALTERNATIVE_EXPLORER_URL_TX}${tx?.transactionId}`}
                target="_blank"
                rel="noreferrer"
              ></a>
            </Tooltip>
          </h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {renderCell(
              intl.formatMessage({ id: "TRANSACTION_ID" }),
              <div className={`${valueClass}`}>
                {tx?.transactionId}
                <PasteIcon value={tx?.transactionId} />
              </div>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "HASH" }),
              <div className={`${valueClass}`}>{tx?.hash}</div>
            )}

            <Space />

            {renderCell(
              intl.formatMessage({ id: "TIME" }),
              <div className={`${valueClass} d-flex align-items-center`}>
                {tx?.blockTime && formatUnixTime(tx?.blockTime / 1e3)}
                <span className="badge badge-light ms-2">
                  {getTimeDifference(tx?.blockTime || 0, false)}
                </span>
              </div>
            )}
            <Space />

            {renderCell(
              intl.formatMessage({ id: "STATUS" }),
              <div className={`${valueClass}`}>
                <TransactionStatusTag
                  accepted={tx?.isAccepted}
                  confirmations={tx?.confirmations}
                  unknown={isLegacyTransaction(tx)}
                />
              </div>
            )}

            <DashSeparator />

            {renderCell(
              intl.formatMessage({ id: "SPONSORED" }),
              <div className="mt-3">
                <AdsPlacement placementType="box" platform={"ads-server"} />
              </div>
            )}

            <DashSeparator />
            {renderCell(
              intl.formatMessage({ id: "BLOCK_HASHES" }),
              <>
                {tx?.blockHash?.map((hash) => {
                  return <BlockHashBullet className={valueClass} hash={hash} />
                })}
              </>
            )}

            {tx?.acceptingBlockHash && (
              <>
                <Space />
                {renderCell(
                  intl.formatMessage({ id: "ACCEPTING_BLOCK_HASH" }),
                  <a
                    role="button"
                    className={`${valueClass} text-hover-primary`}
                    href={
                      tx?.acceptingBlockHash &&
                      getBlockRoute(tx?.acceptingBlockHash)
                    }
                  >
                    {tx?.acceptingBlockHash}
                  </a>
                )}
              </>
            )}

            {acceptedInTime && (
              <>
                <Space />
                {renderCell(
                  intl.formatMessage({ id: "ACCEPTANCE_TIME" }),
                  <div className={`${valueClass}`}>
                    {acceptedInTime &&
                      intl.formatMessage(
                        {
                          id: "ACCEPTANCE_TIME_SECONDS",
                        },
                        {
                          second: (acceptedInTime / 1e3).toPrecision(2),
                        }
                      )}
                  </div>
                )}
              </>
            )}

            {!isCoinbase && (
              <>
                <Space />
                {renderCell(
                  intl.formatMessage({ id: "TRANSACTION_FEE" }),
                  <div className={`${valueClass} d-flex align-items-center`}>
                    {formatKaspaAmount(transactionFee)}
                    <FiatAmountBadge
                      kaspaAmount={kaspaAmountToInt(transactionFee)}
                      className="me-2 ms-2"
                      showLessThan
                    />
                    <span className={`badge badge-light`}>Mass: {mass}</span>
                  </div>
                )}
              </>
            )}

            <Space />
            {renderCell(
              intl.formatMessage({ id: "AMOUNT_TRANSACTED" }),
              <div className={`${valueClass} d-flex align-items-center`}>
                {formatKaspaAmount(transactedAmount)}
                <FiatAmountBadge
                  kaspaAmount={kaspaAmountToInt(transactedAmount)}
                />
              </div>
            )}

            <Space />
          </>
        )}
      </div>
    </div>
  )
}

export { TransactionHeader }
