/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { TokenService } from "../../../client"
import { TITLE_SUFFIX } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { getRandomAdsPlatfrom } from "../../utils/utils"
import { TokenHeader } from "./components/TokenHeader"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { TokenTransactionsWrapper } from "./TokenTransactionsWrapper"
import { TransactionViewType } from "../address/components/AddressTransactions"
import { Segmented } from "antd"
import { SwapOutlined, UserOutlined } from "@ant-design/icons"
import { TokenHoldersList } from "./components/TokenHoldersList"
import { useIntl } from "react-intl"

const mobileBottomMarginClass = "mb-5 mb-xl-0"
const row = `row g-xl-8 ${mobileBottomMarginClass} `

const TokenWrapper = () => {
  const intl = useIntl()
  let { ticker = "" } = useParams()
  const [viewType, setViewType] = useState<TransactionViewType>(
    TransactionViewType.TokenTransfer
  )

  const [adsPlatform] = useState(() => getRandomAdsPlatfrom())

  const { data: tokenSummary, loading: tokenSummaryLoading } = useRequest(
    async () => await TokenService.tokenControllerGetTokenSummary(ticker)
  )

  return (
    <div className={row}>
      <HelmetWrapper
        title={`KRC20 Token ${ticker} ${TITLE_SUFFIX}`}
        description={`Details of Kaspa KRC20 Token ${ticker}`}
      />
      <div className={"col-xl-4"}>
        <TokenHeader token={tokenSummary} loading={tokenSummaryLoading} />
      </div>

      <div className={"col-xl-8"}>
        <div className={"card mb-5 mb-xl-10"} style={{ minHeight: "10vh" }}>
          <div className="card-header border-bottom">
            <div className="card-title m-0 d-flex align-items-center">
              <Segmented
                defaultValue={viewType}
                onChange={(value) => {
                  setViewType(value as TransactionViewType)
                }}
                options={[
                  {
                    label: intl.formatMessage({
                      id: "TOKEN.HEADER.TRANSFERS",
                    }),
                    value: TransactionViewType.TokenTransfer,
                    icon: <SwapOutlined />,
                  },
                  {
                    label: intl.formatMessage({
                      id: "TOKEN.HEADER.HOLDERS",
                    }),
                    value: TransactionViewType.TokenHolders,
                    icon: <UserOutlined />,
                  },
                ]}
              />
            </div>
          </div>

          {viewType === TransactionViewType.TokenTransfer ? (
            <TokenTransactionsWrapper ticker={ticker} hideHeader />
          ) : (
            <TokenHoldersList
              tokenData={tokenSummary}
              tokekHolders={tokenSummary?.holders}
            />
          )}
        </div>

        <div className="mt-5">
          <AdsPlacement placementType="banner" platform={adsPlatform} />
        </div>
      </div>
    </div>
  )
}

export { TokenWrapper }
