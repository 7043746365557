import { Timeline } from "antd"
import { PRIMARY_COLOR } from "../../../constants/constants"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"
import { getAddressRoute, getTransactionRoute } from "../../../hooks/navigator"
import {
  formatKaspaAmount,
  kaspaAmountToInt,
  shortenKaspaAddress,
} from "../../../utils/utils"
import { TransactionType } from "./LiveActivitiesSection"
import { useIntl } from "react-intl"

type Props = {
  className?: string
  transactions?: TransactionType[]
}

export const LiveTransactionsTable: React.FC<Props> = ({
  transactions,
  className,
}) => {
  const intl = useIntl()

  const timelineRow = (tx: TransactionType) => {
    return (
      <div>
        <a
          href={`${getAddressRoute(tx.address)}`}
          className="text-dark fw-bold text-hover-primary fs-6"
        >
          {shortenKaspaAddress(tx.address, 0, 50)}
        </a>
        <span className="mx-2 text-muted">
          {intl.formatMessage({
            id: "DASHBOARD.WHALE_MOVEMENT.RECEIVED",
          })}
        </span>
        <a
          href={`${getTransactionRoute(tx.transactionId)}`}
          className="text-dark fw-bold text-hover-primary fs-6"
        >
          {formatKaspaAmount(tx.amount, 2)}
        </a>
        <FiatAmountBadge kaspaAmount={kaspaAmountToInt(tx.amount)} />
      </div>
    )
  }

  const timelineItems = transactions
    ? transactions
        .map((tx) => {
          return {
            children: timelineRow(tx),
            color: PRIMARY_COLOR,
          }
        })
        .flat()
    : []

  return (
    <div className={`table-responsive navbar-nav-scroll ${className}`}>
      <Timeline style={{ marginTop: 4 }} items={timelineItems} />
    </div>
  )
}
