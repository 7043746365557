import { getTransactionRoute } from "../hooks/navigator"
import { shortenHash } from "../utils/utils"
import { PasteIcon } from "./PasteIcon"

export const TransactionIdLabel = ({
  transactionId,
}: {
  transactionId?: string
}) => {
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="d-flex mx-10 mb-5 text-muted fs-7 align-items-center">
        ID:
        <a
          role="button"
          className="text-muted text-hover-primary ms-1 txn-id"
          href={transactionId && getTransactionRoute(transactionId)}
        >
          <div className="d-none d-md-block">{transactionId}</div>
          <div className="d-block d-md-none">{shortenHash(transactionId)}</div>
        </a>
        <PasteIcon value={transactionId} />
      </div>
    </div>
  )
}
