import { useGlobalData } from "../utils/GlobalDataProvider"

export const FiatAmountBadge = ({
  kaspaAmount,
  className = "ms-2",
  precision = 2,
  showLessThan = false,
}: {
  kaspaAmount: number
  className?: string
  precision?: number
  showLessThan?: boolean
}) => {
  const { marketData } = useGlobalData()
  if (!marketData?.price) return null

  const fiatAmount = marketData?.price
    ? `$${(marketData.price * kaspaAmount).toLocaleString(undefined, {
        maximumFractionDigits: precision,
      })}`
    : "$"

  if (showLessThan && marketData.price * kaspaAmount < 0.01) {
    // Show < 0.01$
    return <span className={`badge badge-light ${className}`}>{"< $0.01"}</span>
  }

  return <span className={`badge badge-light ${className}`}>{fiatAmount}</span>
}

export const FiatAmount = ({
  kaspaAmount,
  precision = 2,
}: {
  kaspaAmount?: number
  precision?: number
}) => {
  const { marketData } = useGlobalData()
  if (!marketData?.price || !kaspaAmount) return null

  return marketData?.price
    ? `$${(marketData.price * kaspaAmount).toLocaleString(undefined, {
        maximumFractionDigits: precision,
      })}`
    : "$"
}
