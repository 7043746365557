/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl"
import { KSPR_AFFILIATE_URL } from "../../constants/constants"

export const TradeOnKSPRBotButton = ({ ticker }: { ticker?: string }) => {
  const intl = useIntl()

  return (
    <a
      className="btn btn-info btn-sm"
      href={KSPR_AFFILIATE_URL}
      target="_blank"
    >
      <i className="bi bi-robot fs-4 me-2"></i>
      <span className="fs-6 fw-semibold">
        {intl.formatMessage(
          { id: "TRADE_ON_KSPR_BOT_FOR_TICKER" },
          { ticker: ticker || intl.formatMessage({ id: "KRC20_TOKENS" }) }
        )}
      </span>
    </a>
  )
}
