/* eslint-disable jsx-a11y/anchor-is-valid */
import { Skeleton, Tooltip } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { useEffect, useRef, useState } from "react"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { useLocalStorageState } from "ahooks"
import { dashboardLiveTransactionShowCoinbaseKey } from "../../../constants/localStorageKeys"
import { Card } from "../../../components/Card"
import { LiveBlocksTable } from "./LiveBlocksTable"
import { KaspadBlockType, KaspadTransactionType } from "../../../utils/types"
import { LiveTransactionsTable } from "./LiveTransactionsTable"

export interface TransactionType {
  timestamp: number
  transactionId: string
  address: string
  amount?: number // old field - backward compatibility
  value?: number
}

const TABLE_HEIGHT = 450

type Props = {
  className: string
  activityType: "blocks" | "transactions"
}

const LiveActivitiesSection: React.FC<Props> = ({
  className,
  activityType,
}) => {
  const intl = useIntl()
  const [showCoinbaseTx, setShowCoinbaseTx] = useLocalStorageState<boolean>(
    dashboardLiveTransactionShowCoinbaseKey,
    {
      defaultValue: false,
    }
  )

  const { kaspadSocket } = useGlobalData()

  const [transactions, setTransactions] = useState<TransactionType[]>()
  const transactionsRef = useRef(transactions)
  transactionsRef.current = transactions

  const [blocks, setBlocks] = useState<KaspadBlockType[]>()
  const blocksRef = useRef(blocks)
  blocksRef.current = blocks

  const hashTx = (tx: TransactionType) => {
    return `${tx.transactionId}-${tx.address}`
  }

  useEffect(() => {
    function connectSocket() {
      kaspadSocket.on("connect", () => {
        kaspadSocket.emit("join-room", "blocks")
      })

      kaspadSocket.on("new-block", (d: KaspadBlockType) => {
        const prevTxHashes = (transactionsRef.current || []).map((i) =>
          hashTx(i)
        )

        const incomingTxs = (
          d.transactions as unknown as KaspadTransactionType[]
        )
          .filter((tx) =>
            showCoinbaseTx ? true : tx.inputs && tx.inputs?.length > 0
          )
          .map((tx) =>
            tx.outputs
              ?.map((output) => {
                return {
                  transactionId: tx.verboseData.transactionId,
                  amount: output.amount || output.value,
                  address: output.verboseData.scriptPublicKeyAddress,
                  timestamp: tx.verboseData.blockTime,
                }
              })
              .flat()
          )
          .flat()
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.address === value.address &&
                  t.transactionId === value.transactionId
              )
          )
          .filter((tx) => !prevTxHashes.includes(hashTx(tx)))

        const newTxs = [
          ...(incomingTxs || []),
          ...(transactionsRef.current || []),
        ].slice(0, 100)
        const newBlocks = [d, ...(blocksRef.current || [])].slice(0, 100)

        setTransactions(newTxs)
        setBlocks(newBlocks)
      })

      return () => {
        kaspadSocket.off("connect")
        kaspadSocket.off("new-block")
      }
    }

    connectSocket()
  }, [kaspadSocket, showCoinbaseTx])

  return (
    <Card
      className={className}
      title={
        <>
          <span className="spinner-border spinner-border-sm me-4 text-primary"></span>
          <span className="me-2">
            {activityType === "blocks"
              ? intl.formatMessage({ id: "DASHBOARD.LIVE_BLOCKS.TITLE" })
              : intl.formatMessage({ id: "DASHBOARD.TRANSACTION.TITLE" })}
          </span>
          {activityType === "transactions" && (
            <Tooltip
              title={intl.formatMessage({
                id: `DASHBOARD.TRANSACTION.TOOLBAR.FILTER.${
                  showCoinbaseTx ? "ON" : "OFF"
                }`,
              })}
            >
              <button
                type="button"
                className={`bi ${
                  showCoinbaseTx ? "bi-funnel" : "bi-funnel-fill"
                } btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-3`}
                onClick={() => setShowCoinbaseTx(!showCoinbaseTx)}
              />
            </Tooltip>
          )}
        </>
      }
      body={
        <Skeleton
          style={{ height: TABLE_HEIGHT + 20 }}
          paragraph={{ rows: 11 }}
          active
          loading={
            activityType === "blocks"
              ? !blocks || blocks.length === 0
              : !transactions || transactions.length === 0
          }
        >
          {activityType === "blocks" && (
            <LiveBlocksTable
              className={`h-${TABLE_HEIGHT}px`}
              blocks={blocks}
            />
          )}
          {activityType === "transactions" && (
            <LiveTransactionsTable
              className={`h-${TABLE_HEIGHT}px`}
              transactions={transactions}
            />
          )}
        </Skeleton>
      }
    />
  )
}

export { LiveActivitiesSection }
