/* eslint-disable jsx-a11y/anchor-is-valid */
import { Skeleton, Tooltip } from "antd"
import moment from "moment"
import { KTSVG } from "../../../../_metronic/helpers"
import { TransactionIdLabel } from "../../../components/TransactionIdLabel"
import { TokenOpStatusTag } from "../../../components/TokenOpStatusTag"
import { TransactionSide } from "../../transaction/TransactionSide"
import { formatTokenAmount } from "../../../utils/tokenUtils"
import { getTokenTrasactionType } from "../utils"
import { getAddressRoute, getTokenRoute } from "../../../hooks/navigator"
import { OperaionType, TokenTransactionResponse } from "../../../../client"
import { shortenKaspaAddressWithPrefix } from "../../../utils/utils"
import { useIntl } from "react-intl"
import { FiatAmountBadge } from "../../../components/FiatAmountBadge"

interface TokenTransactionProps {
  tx: TokenTransactionResponse
  decimal?: number
  address: string
  traceEnabled?: boolean
  loading?: boolean
}

export const TokenTransactionTrace = ({ address }: TokenTransactionProps) => {
  return (
    <div className="row">
      <div className="col-xl-6">
        <TransactionSide
          type="out"
          className="card-xl-stretch"
          highlightAddress={address}
          // txs={tx.inputs || []}
        />
      </div>
      <div className="col-xl-6">
        <TransactionSide
          type="in"
          className="card-xl-stretch"
          highlightAddress={address}
          // txs={tx.outputs || []}
        />
      </div>
    </div>
  )
}

const TransactionAddressContent = ({
  tx,
  address,
}: {
  tx: TokenTransactionResponse
  address: string
}) => {
  if (tx.operation === OperaionType.TRANSFER) {
    return (
      <span className="text-muted">
        {"("}
        <Tooltip title={tx.fromAddress}>
          <a
            role="button"
            className="text-muted text-hover-primary text-break"
            href={getAddressRoute(tx.fromAddress)}
          >
            {tx.fromAddress.slice(-6)}
          </a>
        </Tooltip>
        {" -> "}
        <Tooltip title={tx.toAddress}>
          <a
            role="button"
            className="text-muted text-hover-primary text-break"
            href={getAddressRoute(tx.toAddress)}
          >
            {tx.toAddress.slice(-6)}
          </a>
        </Tooltip>
        {")"}
      </span>
    )
  }

  return (
    <span className="text-muted">
      {"("}
      <Tooltip title={tx.toAddress}>
        <a
          role="button"
          className="text-muted text-hover-primary text-break"
          href={getAddressRoute(tx.toAddress)}
        >
          {shortenKaspaAddressWithPrefix(tx.toAddress, 4, 4)}
        </a>
      </Tooltip>
      {")"}
    </span>
  )
}

export const TokenTransactionRow = ({
  tx,
  decimal,
  address,
  traceEnabled,
  loading,
}: TokenTransactionProps) => {
  const intl = useIntl()

  if (loading) {
    return (
      <div className="card-body border-top p-10 d-flex justify-content-between align-items-start">
        <Skeleton active />
      </div>
    )
  }

  const txAmount = parseFloat((tx.amount || 0).toPrecision(decimal || 8))
  const txType = getTokenTrasactionType(tx, address, intl)

  return (
    <>
      <div className="card-body border-top p-5 d-flex justify-content-between align-items-start">
        <div className="ms-5 fs-6 fw-bold">
          <div className="d-flex align-items-center">
            <span className="svg-icon svg-icon-success me-2">
              <KTSVG
                path={txType.iconPath}
                className={`svg-icon-2 ${txType.iconClass}`}
              />
            </span>
            <span className="me-2">{txType.type}</span>
            <TransactionAddressContent tx={tx} address={address} />
          </div>
          <div className="d-flex align-items-center flex-nowrap mt-1">
            <span className="text-nowrap">
              {formatTokenAmount("", Math.abs(txAmount), tx.tokenInfo?.decimal)}
              <a
                role="button"
                className="text-dark text-hover-primary text-break"
                href={getTokenRoute(tx.ticker)}
              >
                {tx.ticker}
              </a>
            </span>
            {tx.tokenInfo?.price?.floorPrice && (
              <FiatAmountBadge
                kaspaAmount={
                  (txAmount / Math.pow(10, decimal || 1)) *
                  tx.tokenInfo?.price?.floorPrice
                }
                precision={2}
              />
            )}
          </div>
        </div>
        <div className="fs-6 fw-bold text-end me-5 text-end">
          <TokenOpStatusTag accepted={tx.opAccepted === 1} error={tx.opError} />
          <div className="fw-normal text-muted">
            {tx.createdAt &&
              moment.unix(tx.createdAt / 1e3).format("YYYY-MM-DD")}{" "}
            <div className="d-md-none"></div>
            {tx.createdAt && moment.unix(tx.createdAt / 1e3).format("HH:mm:ss")}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <TransactionIdLabel transactionId={tx.revealHash} />
      </div>

      {/* {(traceEnabled || expanded) && (
        <TokenTransactionTrace tx={tx} address={address} />
      )} */}
    </>
  )
}
