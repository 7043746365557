import { useIntl } from "react-intl"
import { rowClass, TITLE_SUFFIX, UNIT_STRING } from "../../constants/constants"
import { HelmetWrapper } from "../../utils/HelmetWrappper"
import { formatKaspaAmount, kaspaAmountToInt } from "../../utils/utils"
import { AdsPlacement } from "../../components/Ads/AdsPlacement"
import { useRequest } from "ahooks"
import { TokenService } from "../../../client"
import { metricsCardClass } from "../dashboard/components/TopMetricsSection"
import { MetricCard } from "../dashboard/components/MetricCard"
import { Krc20TokenList } from "./Krc20TokenList"

const Krc20TokenDashboard = () => {
  const intl = useIntl()

  const { data: networkStatus, loading } = useRequest(
    TokenService.tokenControllerGetKrc20Status
  )
  const { data: tokens, loading: tokenLoading } = useRequest(
    TokenService.tokenControllerGetKrc20TokenList
  )

  const cards = [
    {
      svgIcon: "/media/icons/duotune/general/gen016.svg",
      title: networkStatus?.opTotal
        ? Number(networkStatus?.opTotal)
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOTAL_TRANSFERS",
      }),
    },
    {
      svgIcon: "/media/icons/duotune/finance/fin008.svg",
      title: networkStatus?.feeTotal
        ? kaspaAmountToInt(Number(networkStatus.feeTotal))
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOTAL_FEES",
      }),
      titleSuffix: ` ${UNIT_STRING}`,
    },
    {
      svgIcon: "/media/icons/duotune/coding/cod004.svg",
      title: networkStatus?.tokenTotal
        ? Number(networkStatus?.tokenTotal)
        : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.TOTAL_TOKENS",
      }),
    },
    {
      svgIcon: "/media/icons/duotune/coding/cod001.svg",
      title: networkStatus?.version ? `v${networkStatus?.version}` : undefined,
      description: intl.formatMessage({
        id: "TOKEN.HEADER.METRICS.PROTOCOL_VERSION",
      }),
    },
  ]

  return (
    <>
      <HelmetWrapper
        title={`Kaspa KRC20 Tokens Dashboard ${TITLE_SUFFIX}`}
        description={"In-depth view of Kaspa's KRC20 Tokens"}
      />
      <div className={"row"}>
        {cards.map((row) => {
          return (
            <div className={metricsCardClass}>
              <MetricCard
                className="bg-body card-xl-stretch mb-xl-8"
                titleClass="text-dark"
                descriptionClass="text-muted"
                iconClass="svg-icon-primary"
                {...row}
              />
            </div>
          )
        })}
      </div>

      <div className={rowClass}>
        <div className="col-xl-12 mb-8">
          <Krc20TokenList tokens={tokens?.results} />
        </div>

        <div className="mt-0 mt-xl-n2">
          <AdsPlacement placementType="banner" platform={"ads-server"} />
        </div>
      </div>
    </>
  )
}

export { Krc20TokenDashboard }
