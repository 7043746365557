import { Empty } from "antd"
import { useIntl } from "react-intl"

export const EmptyState = ({ textId }: { textId: string }) => {
  const intl = useIntl()
  return (
    <div className="mb-10">
      <Empty description={intl.formatMessage({ id: textId })} />
    </div>
  )
}
