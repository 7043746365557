import { Tooltip } from "antd"
import { formatTokenAmount } from "../../utils/tokenUtils"
import { GetTokenSummaryResponse } from "../../../client"

export const PremintPctBadge = ({
  token,
}: {
  token: GetTokenSummaryResponse
}) => {
  const ticker = token.ticker || ""
  const { preMint, decimal, maxSupply } = token
  const preMintedPct = (preMint / maxSupply) * 100

  const preMintedBadgeType = (premintedPct: number) => {
    if (premintedPct > 90) {
      return "light-danger"
    } else if (premintedPct > 50) {
      return "light-warning"
    } else if (premintedPct > 5) {
      return "secondary"
    } else {
      return "light-success"
    }
  }
  return (
    <Tooltip title={formatTokenAmount(ticker, preMint, decimal)}>
      <span className={`badge badge-${preMintedBadgeType(preMintedPct)}`}>
        {preMintedPct.toFixed(2)}%
      </span>
    </Tooltip>
  )
}
